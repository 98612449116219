<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("client.informationClient") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'adminTenants' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="saveBaseSettings">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">{{ $t("settings.numberRanges") }}:</h5>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("settings.tenantNumberRange") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <input
                v-model="baseSettings['tenantNumberRange'].value"
                type="text"
                class="form-control"
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fal fa-lock icon-lg"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="baseSettings.partnerNumberRange"
          class="form-group row align-items-center"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("settings.partnerNumberRange") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <input
                v-model="baseSettings['partnerNumberRange'].value"
                type="text"
                class="form-control"
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fal fa-lock icon-lg"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import AdminSettings from "@/components/Admins/Settings/System/settings";

export default {
  name: "AdminsSettingsBillingNumberRanges",
  data() {
    return {
      isBusy: false,
      baseSettings: {
        tenantNumberRange: false,
        partnerNumberRange: false
      }
    };
  },
  mounted() {
    this.loadBaseSettings();
  },
  methods: {
    loadBaseSettings() {
      this.isBusy = true;
      let settingNames = ["tenantNumberRange", "partnerNumberRange"];

      settingNames.forEach(settingName => {
        AdminSettings.getByName(settingName)
          .then(response => {
            this.baseSettings[settingName] = response.data.data;
            this.isBusy = false;
          })
          .catch(e => {
            this.baseSettings[settingName] = false;
            console.log(e);
          });
      });

      this.isBusy = false;
    },
    async saveBaseSettings() {
      let erros = [];
      this.isBusy = true;
      let settingsToUpdate = ["tenantNumberRange", "partnerNumberRange"];

      for (const settingName of settingsToUpdate) {
        let settingData = this.baseSettings[settingName];
        if (settingData) {
          await AdminSettings.update(settingData.id, settingData)
            .then(response => {
              this.baseSettings[settingName] = response.data.data;
            })
            .catch(() => {
              erros.push(
                this.$t("settings.settingNameNotUpdated", {
                  setting: settingData.label
                })
              );
            });
        }
      }

      this.isBusy = false;

      if (erros.length === 0) {
        this.$toast.fire({
          icon: "success",
          title: this.$t("settings.settingUpdated")
        });
        return true;
      }

      if (erros.length >= 1) {
        this.$toast.fire({
          icon: "error",
          title: erros[0]
        });
      }
    }
  }
};
</script>
